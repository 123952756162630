//if metamask is installed but not connected
export const handleConnect = async () => {
  await window.ethereum.enable();
};

export const handleLogout = async () => {
  await window.ethereum.clearCachedProvider();
};

export const handleChangeNetwork = async () => {
  if (window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: process.env.REACT_APP_NAME_NETWORK,
              chainId: process.env.REACT_APP_CHAIN_ID,
              rpcUrls: [process.env.REACT_APP_RPC_URL],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB", // 2-6 characters long
                decimals: 18,
              },
            },
          ],
        });
      }
    }
  }
};

//if metamask is not installed at all
export const handleInstall = () => {
  window.open("https://metamask.io/download.html", "_blank");
};
