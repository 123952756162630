import React, { useContext } from "react";
import Header from "../components/layout/Header";
import AppContext from "../../../appContext";
import "../assets/scss/landingPage.scoped.scss";
import { Dimmer, Loader } from "semantic-ui-react";

const LayoutDefault = ({ children, metoTokens, daiTokens, rpcError }) => {
  const { screenBlocked } = useContext(AppContext);
  return (
    <>
      <Header
        navPosition="right"
        className="reveal-from-bottom"
        rpcError={rpcError}
      />
      <main className="site-content" style={{marginTop: 20}}>{children}</main>
      <Dimmer
        active={screenBlocked}
        style={{ zIndex: "1000000000000", position: "fixed" }}
      >
        <Loader
          indeterminate
          content="Waiting for transaction to finish......"
        />
      </Dimmer>
    </>
  );
};

export default LayoutDefault;
