import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import ScrollReveal from "./utils/ScrollReveal";
import AppContext from "../../appContext";
import "../landingPage/assets/scss/landingPage.scoped.scss";
import abiDecoder from 'abi-decoder'
import {
  approveCHTSTokensToTransfer,
  convertToTokens,
  checkCHTSTokensFor,
  // getInfoPackage,
  stakeToFarm,
  getHistoryStake,
  getCurrentClaimInfo,
  claimProfit,
  claimStake,
} from "../../utils/assets";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import { toast } from "react-toastify";

import notifyBG from "../landingPage/assets/images/bg-notify.svg";
import iconSuccess from "../landingPage/assets/images/icon-success.svg";

// Views
import Home from "./views/Home";

import {
  PACKAGE_6_MONTHS,
  PACKAGE_12_MONTHS,
  PACKAGE_3_MONTHS,
} from "../../config";

import ModalSetting from "./components/sections/ModalSetting";
import * as Sentry from "@sentry/react";
const LandingPage = () => {
  const {
    web3,
    account,
    handleBlockScreen,
    screenBlocked,
    openSetting,
    toggleSetting,
  } = useContext(AppContext);

  // account = '0x713033ffA6709F79E967a4fB69Aa89bfc81A0A8f'
  const childRef = useRef();
  let location = useLocation();

  const Layout =
    LayoutDefault === undefined
      ? (props) => <>{props.children}</>
      : LayoutDefault;

  const [rpcError, setRpcError] = useState(false);
  const [CHTSToken, setCHTSToken] = useState(0);

  const [isApproveToTransfer, setApprove] = useState(0);
  const [historyByPackage, setHistory] = useState({
    [PACKAGE_6_MONTHS]: [],
    [PACKAGE_12_MONTHS]: [],
    [PACKAGE_3_MONTHS]: [],
  });

  const notify = (message, isFailed = false) => {
    if (isFailed === false)
      toast.success(
        message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    else {
      toast.warning(
        message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }



  const handleTokensChange = useCallback(async () => {
    handleBlockScreen(true);
    try {
      const { balance, isApprove } = await checkCHTSTokensFor(web3, account);

      function floorFigure(figure, decimals) {
        if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return Number((parseInt(figure * d) / d).toFixed(decimals));
      }

      setCHTSToken(floorFigure(+convertToTokens(balance, web3), 3));
      setApprove(isApprove);

      // const { packageOne, packageTwo, packageThree } = await getInfoPackage(
      //   web3
      // );

      const history = await getHistoryStake(web3, account);

      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );

      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);
    } catch (err) { 
      if(err.message === `Invalid JSON RPC response: ""`){
        setRpcError(true);
      }
    }

    handleBlockScreen(false);
  }, [account, web3]);

  const handleHistory = async () => {
    if (screenBlocked) {
      return;
    }
    try {
      const history = await getHistoryStake(web3, account);
      const { resultProfit, resultStake } = await getCurrentClaimInfo(
        web3,
        Object.keys(history)
      );
      console.log(resultProfit);
      Object.keys(resultProfit).forEach((key) => {
        history[key].profitCanClaim =
          resultProfit[key] - history[key].profitClaimed;
      });

      Object.keys(resultStake).forEach((key) => {
        history[key].stakeCanClaim =
          resultStake[key] - history[key].stakeClaimed;
      });

      const newHistoryByPackage = {
        [PACKAGE_6_MONTHS]: [],
        [PACKAGE_12_MONTHS]: [],
        [PACKAGE_3_MONTHS]: [],
      };

      Object.keys(history).forEach((profileId) => {
        const packageId = history[profileId].packageId;
        newHistoryByPackage[packageId].push(history[profileId]);
      });

      setHistory(newHistoryByPackage);
    } catch (e) { 
      // if(e.message.contains(`Internal JSON-RPC error.`)){
      //   console.log("e: ", e.message);
        
      //   setRpcError(true);
      // }
    }
  };

  useEffect(() => {
    (async () => {
      //it could happen that account is empty when this useEffect runs initially, hence the guard
      if (account) {
        handleTokensChange();
      } else {
        setHistory({});
      }
    })();
  }, [account, handleTokensChange]);

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let intervalId = useRef(0);

  useEffect(() => {
    if (account) {
      intervalId.current = setInterval(handleHistory, 10000);
    } else {
      clearInterval(intervalId.current);
    }

    return () => clearInterval(intervalId.current);
  }, [web3, account]);

  const onApproveToTransfer = useCallback(async () => {
    handleBlockScreen(true);
    try {
      await approveCHTSTokensToTransfer(web3, account);
      setApprove(true);
      handleBlockScreen(false);

      return true;
    } catch (e) {
      const error = e.message;
      Sentry.captureException({
        message: error,
        address: account,
        type: "onApproveToTransfer"
      });
      if (error.includes("insufficient funds")) {
        notify("Insufficient BNB", true);
      } else {
        notify("Enable failed", true);
      }
    }
    handleBlockScreen(false);
    return false;
  }, [web3, account]);

  const onStake = useCallback(
    async (type, numberCoin) => {

      handleBlockScreen(true);
      try {
        await stakeToFarm(web3, account, numberCoin, type);
        const { balance } = await checkCHTSTokensFor(web3, account);
        setCHTSToken(+convertToTokens(balance, web3));
        await handleTokensChange();
        notify("Staking success");
      } catch (e) {
        const error = e.message;
        Sentry.captureException({
          message: error,
          address: account,
          type: "onStake",
          numberCoin,
          type: "onStake",
          package: type
        });
        if (error.includes("insufficient funds")) {
          notify("Insufficient BNB", true);
        } else {
          notify("Staking failed", true);
        }
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onHarvest = useCallback(
    async (profileId, type, numberCoin) => {
      handleBlockScreen(true);
      try {
        try {
           await claimProfit(web3, account, profileId);
          await handleTokensChange();   
          notify("Withdrawal success");
        } catch (e) { 
          Sentry.captureException({
            message: e.message,
            address: account,
            packageId: profileId,
            type: "onHarvest"
          });
        }
      } catch (e) {
        const error = e.message;
        Sentry.captureException(error);
        if (error.includes("insufficient funds")) {
          notify("Insufficient BNB", true);
        } else {
          notify("Withdrawal failed", true);
        }
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  const onUnBlock = useCallback(
    async (profileId) => {
      handleBlockScreen(true);
      try {
        await claimStake(web3, account, profileId);
        await handleTokensChange();
        notify("Unlock success");
      } catch (e) {
        const error = e.message;
        Sentry.captureException({
          message: error,
          address: account,
          packageId: profileId,
          type: "onUnBlock"
        });
        if (error.includes("insufficient funds")) {
          notify("Insufficient BNB", true);
        } else {
          notify("Unlock failed");
        }
      }

      handleBlockScreen(false);
    },
    [web3, account]
  );

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Layout rpcError={rpcError}>
            <Home
              balance={CHTSToken}
              isApproveToTransfer={isApproveToTransfer}
              onApprove={onApproveToTransfer}
              onStake={onStake}
              historyByPackage={historyByPackage}
              onHarvest={onHarvest}
              onUnBlock={onUnBlock}
            />
            <ModalSetting
              open={openSetting}
              balance={CHTSToken}
              account={account}
              toggleModal={toggleSetting}
            />
          </Layout>
        )}
      />
    </>
  );
};

export default LandingPage;
