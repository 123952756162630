import React, { useState } from 'react';
import { Modal, Image } from 'semantic-ui-react';
import cardImg from '../../assets/images/bg-modal-1.png';
import closeImg from '../../assets/images/icon-close.svg';
import chtsIcon from '../../assets/images/icon-chts.png';
import CopyIcon from '../elements/CopyIcon';

import { formatTotalCoin } from '../../../../utils/helper';
import CloseIcon from '../elements/CloseIcon';
import OpenLink from '../elements/OpenLink';

function ModalSetting({ open, toggleModal, balance = 1000, account }) {
  const [isCopied, setCopied] = useState(false);

  const onCopyAccount = () => {
    navigator.clipboard.writeText(account);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Modal onClose={toggleModal} open={open} className="modal-account">
      <div className="modal-header">
        <p>Your Wallet</p>

        <CloseIcon className="close-container" width="24px" onClick={toggleModal} color="#777777" />
        {/* <StyledCloseIcon width="24px" onClick={closeModalHandler} color="#777777" /> */}
      </div>
      <div className="modal-break-line" />
      <div className="modal-inner">
        <div className="address-title">
          <p className="">Your address:</p>
    {
      isCopied &&  <div className="copied-wrapper">
      <p>Copied!</p>
    </div>
    }
         
        </div>

        <div className="account-box">
          <div className="account-address">{account}</div>
          <CopyIcon width="24px" style={{ cursor: 'pointer' }} onClick={onCopyAccount} fill="black" />
        </div>

        <div className="mb-3">
          <div className="account-balance">
            <div className="balance-title">Dozen Balance:</div>

            <div className="balance-amount">
              {`${formatTotalCoin(balance)}`}

              {/* <StyledImg src="/tokens/BGOF.png" width="24px" className="ms-1" /> */}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
            <a
              className="account-external-link"
              href={`https://testnet.bscscan.com/address/${account}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                View on BscScan
              </div>
              <OpenLink width="18px" fill="" />
            </a>
          </div>
      </div>

      {/* <div className="inner-modal">
        <Image src={cardImg} className="bg-modal" />

        <div className="modal-header">
          <p className="text-deposite">Account</p>
          <div onClick={toggleModal} className="close-container">
            <Image src={closeImg} className="icon-close" />
          </div>
        </div>
        <div className="flex-row">
          <span className="setting-balance">Your address: </span>

          <span className="setting-balance">{`${hiddenAddress}`}</span>
        </div>
        <div className="flex-row">
          <span className="setting-balance">Your balance: </span>
          <span className="setting-balance">
            <Image src={chtsIcon} className="icon-chts" />

            {`${formatTotalCoin(balance)}`}
          </span>
        </div>
      </div> */}
    </Modal>
  );
}

export default ModalSetting;
