import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import getWeb3 from './web3-config';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
//inject web3 into the App.js
Sentry.init({
    dsn: "https://87f402f123a44cbb84b658767f0ee386@o4503924049772544.ingest.sentry.io/4503928310661120",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
})
getWeb3().then(web3 => {
    ReactDOM.render(
        <BrowserRouter>
            <App web3={web3} />
        </BrowserRouter>,
        document.getElementById('root')
    );
});

serviceWorker.unregister();