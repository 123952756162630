import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AppContext from "./appContext";
import LandingPage from "./pages/landingPage/LandingPage";
import GuidePage from "./pages/landingPage/GuidePage";

import "semantic-ui-css/semantic.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";

const App = ({ web3 }) => {
  const [account, setAccount] = useState("");
  const [networkId, setNetworkId] = useState("");
  const [hasWalletAddress, setHasWalletAddress] = useState(false);
  const [hasAccountChanged, setHasAccountChanged] = useState(false);
  const [screenBlocked, setScreenBlocked] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  //const location = useLocation();

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        try {
          const networkId = await web3.eth.net.getId();
          setNetworkId(networkId);
          const [selectedAccount] = await web3.eth.getAccounts();
          setAccount(web3.utils.toChecksumAddress(selectedAccount));
          window.ethereum.on("accountsChanged", (accounts) => {
            setHasAccountChanged(true);

            if (!accounts[0]) {
              setHasWalletAddress(false);
              setAccount("");
            } else {
              setHasWalletAddress(true);
              setAccount(accounts[0]);
            }
          });
          window.ethereum.on("chainChanged", (_chainId) =>
            window.location.reload()
          );
        } catch (error) {
        }
      }
    };
    init();
  }, [web3.utils, web3.eth]);

  const handleBlockScreen = (blocked) => {
    setScreenBlocked(blocked);
  };

  const handleAccountChanged = (newHasAccountChanged) => {
    setHasAccountChanged(newHasAccountChanged);
  };

  const toggleSetting = () => setOpenSetting(!openSetting);

  return (
    <AppContext.Provider
      value={{
        web3,
        handleBlockScreen,
        screenBlocked,
        account,
        hasWalletAddress,
        hasAccountChanged,
        handleAccountChanged,
        networkId,
        openSetting,
        toggleSetting,
      }}
    >
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/guide" component={GuidePage} />
          <Route component={LandingPage} />
        </Switch>
      </Router>

      <ToastContainer id="toast-test" autoClose={2000} />
    </AppContext.Provider>
  );
};

export default App;
