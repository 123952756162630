import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import { Button } from "semantic-ui-react";
import "../../assets/scss/landingPage.scoped.scss";
import {
  handleConnect,
  handleInstall,
  handleChangeNetwork,
} from "../../../../utils/metamask";
import AppContext from "../../../../appContext";
import connectBG from "../../assets/images/connect-wallet.png";
import { Link, useLocation } from "react-router-dom";
import closeButton from "../../assets/images/button_close_play.png";
import { isMobile } from "react-device-detect";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  metoTokens,
  daiTokens,
  rpcError,
  ...props
}) => {
  const { account, hasWalletAddress, toggleSetting, networkId } =
    useContext(AppContext);

  const [isActive, setIsactive] = useState(false);
  const [changeButtonConnct, setChangeButtonConnect] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);

  const location = useLocation();

  const hiddenAddress = account
    ? `${account[0]}${account[1]}${account[2]}...${
        account[account.length - 3]
      }${account[account.length - 2]}${account[account.length - 1]}`
    : "";

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const renderMetaMaskLabel = () => {
    if (window.ethereum) {
      return !hasWalletAddress && !account ? (
        <div className="wallet-address" onClick={handleConnect}>
          Connect Wallet
        </div>
      ) : (
        <div onClick={toggleSetting} className="wallet-address">
          <span>{hiddenAddress}</span>
        </div>
      );
    } else {
      return isMobile ? (
        <a
          href={process.env.REACT_APP_DEEPLINK_METAMASK}
          className="connect-metamask"
        >
          Connect MetaMask
        </a>
      ) : (
        <Button className="connect-metamask" onClick={handleInstall}>
          Install MetaMask
        </Button>
      );
    }
  };

  const isGuideChosen = location?.pathname === "/guide";

  return (
    <header {...props} className={classes}>
      <div style={{position:"fixed", width:"100%"}}>
        {!!(
          networkId.toString() !==
            process.env.REACT_APP_NETWORK_ID.toString() && !isGuideChosen
        ) && (
          <div
            style={{
              color: "#fff",
              textAlign: "center",
              padding: 10,
              fontSize: 18,
              fontWeight: 800,
              background: "#c40000",
            }}
          >
            {`Please connect BSC ${process.env.REACT_APP_NAME_NETWORK} network!`}
            <div onClick={handleChangeNetwork} className="switch-container">
              <span className="switch-txt">SWITCH</span>
            </div>
          </div>
        )}
        {rpcError && (
          <div
            style={{
              color: "#fff",
              textAlign: "center",
              padding: 15,
              fontSize: 18,
              fontWeight: 800,
              background: "red",
            }}
          >
            Warning: Internal RPC Error. Please check RPC in wallet.
          </div>
        )}
      </div>

      <div className="container" style={{ marginTop: `${rpcError || !!(
          networkId.toString() !==
            process.env.REACT_APP_NETWORK_ID.toString() && !isGuideChosen
        )  ? '100px':'10px'}` }}>
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          <div
            style={{
              flex: 1000000,
              textAlign: "center",
            }}
          >
            <Link
              className={`nav-item ${
                !isGuideChosen ? "has-border-bottom" : ""
              }`}
              to="/"
            >
              Staking
            </Link>
            {/* <Link  passHref> */}
            <a className={`nav-item`} href="/guide.pdf" target="_blank" alt="">
              Guide
            </a>
            {/* </Link> */}
          </div>

          {!hideNav && (
            <>
              <label className="menu-button-container" htmlFor="menu-toggle" />
              <div
                onClick={isActive ? closeMenu : openMenu}
                className="menu-button"
                style={{ right: 10, opacity: isMobile ? 1 : 0 }}
              />
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
                style={{ opacity: 0, marginLeft: 30 }}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul className="list-reset header-nav-right">
                    <li>
                      <div
                        rel="noopener noreferrer"
                        className="metamask-wallet"
                        style={{
                          // backgroundImage: `url(${connectBG})`,
                          position: "relative",
                        }}
                        onMouseEnter={() => {
                          setChangeButtonConnect(true);
                        }}
                        onMouseLeave={() => {
                          setChangeButtonConnect(false);
                        }}
                      >
                        <img
                          src={changeButtonConnct ? closeButton : connectBG}
                          className="bg-wallet"
                          alt="bg-wallet"
                        />

                        <div
                          style={{
                            wordBreak: "break-all",
                            color: "#dadada !important",
                            fontSize: "1em",
                            lineHeight: "1.5em",
                            fontWeight: 800,
                          }}
                        >
                          {renderMetaMaskLabel()}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
